import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
    Container,
    Row,
    Col,
    CardPanel,
    Icon,
    Button,
} from 'react-materialize';
import {
    GetBlockchainInfoRequest,
    GetBlockchainInfoResponse,
    GetMempoolInfoRequest,
    GetMempoolInfoResponse,
    SubscribeBlocksRequest,
    BlockNotification,
    SubscribeTransactionsRequest,
    TransactionFilter,
    TransactionNotification,
    MempoolTransaction,
    Transaction,

} from './bchrpc/bchrpc_pb';
import {
    reverseUint8arrIntoHex,
    calcTxValue,
} from './utils'

import { asBCH } from './bch_utils.ts';

import CopyButton from './components/CopyButton';
import './css/Home.css'

import { useStore } from './store.js';


function Home() {
    const { store } = useStore();

    const navigate = useNavigate();

    const [blockchainInfo, setBlockchainInfo] = useState(new GetBlockchainInfoResponse());
    const [blockHeight, setBlockHeight] = useState(0)
    const [mempoolSize, setMempoolSize] = useState(0);
    const [mempoolBytes, setMempoolBytes] = useState(0);
    const [txFeed, setTxFeed] = useState([]);
    const [freeze, setFreeze] = useState(false);


    useEffect(() => {
        function getBlockchainInfo() {
            let blockchainInfoRequest = new GetBlockchainInfoRequest();

            console.log("getBlockchainInfo");

            const call = store.client.getBlockchainInfo(blockchainInfoRequest, store.headers, function (error, response) {
                if (error) {
                    console.log("error getBlockchainInfo", error.code, error.message);
                } else {
                    let blockchainInfoResponse = new GetBlockchainInfoResponse();
                    blockchainInfoResponse = response;
                    setBlockchainInfo(blockchainInfoResponse);
                    setBlockHeight(blockchainInfoResponse.getBestHeight())
                }
            });

            return call;
        }

        const call = getBlockchainInfo();

        return function cleanup() {
            console.log("cleanup getBlockchainInfo");
            call.cancel();
        }

    }, [blockHeight, store.headers, store.client]);


    useEffect(() => {
        function getMempoolInfo() {
            const getMempoolInfoRequest = new GetMempoolInfoRequest();

            console.log("getMempoolInfo");

            const call = store.client.getMempoolInfo(getMempoolInfoRequest, store.headers, function (error, response) {
                if (error) {
                    console.log("error getMempoolInfo: ", error.code, error.message);
                } else {
                    var mempoolInfo = GetMempoolInfoResponse();
                    mempoolInfo = response
                    // console.log("mempoolinfo", mempool);

                    setMempoolSize(mempoolInfo.getSize());
                    setMempoolBytes(mempoolInfo.getBytes());
                }
            });

            return call;
        }

        const call = getMempoolInfo();

        return function cleanup() {
            console.log("cleanup getMempoolInfo");
            call.cancel();
        }

    }, [blockHeight, freeze, store.headers, store.client]);


    useEffect(() => {
        const subscribreBlocksRequest = new SubscribeBlocksRequest();

        console.log("setup subscribeBlocks stream");

        let stream = store.client.subscribeBlocks(subscribreBlocksRequest, store.headers);

        stream.on("data", function (message) {
            let blockNotif = new BlockNotification();
            blockNotif = message;

            setMempoolSize(0);
            setMempoolBytes(0);
            setBlockHeight(blockNotif.getBlockInfo().getHeight());
        });
        stream.on("status", function (status) {
            console.log(status);
        });
        stream.on("end", function (status) {
            console.log(status);
        });

        return function cleanup() {
            console.log("cleanup subscribeBlocks");
            stream.cancel();
        };

    }, [store.headers, store.client]);


    useEffect(() => {
        let filter = new TransactionFilter();
        filter.setAllTransactions(true);

        let subscribreTransactionRequest = new SubscribeTransactionsRequest();
        subscribreTransactionRequest.setIncludeMempool(true);
        subscribreTransactionRequest.setIncludeInBlock(false);
        subscribreTransactionRequest.setSubscribe(filter);

        console.log("setup subscribeTransactions stream");

        let stream = store.client.subscribeTransactions(subscribreTransactionRequest, store.headers);

        stream.on("data", function (message) {
            let txNotif = new TransactionNotification();
            txNotif = message;

            setMempoolSize((mempoolSize) => mempoolSize + 1);
            setMempoolBytes((mempoolBytes) => mempoolBytes + txNotif.getUnconfirmedTransaction().getTransaction().getSize());

            if (!freeze) {
                setTxFeed((txFeed) => {
                    let feedList = [...txFeed];
                    feedList = feedList.slice(-9);
                    feedList.push(txNotif.getUnconfirmedTransaction());

                    return feedList;
                });
            }
        });
        stream.on("status", function (status) {
            console.log(status);
        });
        stream.on("end", function (status) {
            console.log(status);
        });

        return function cleanup() {
            console.log("cleanup subscribeTransactions");
            stream.cancel();
        };

    }, [freeze, store.headers, store.client]);


    function BlockchainInfoCard(props) {
        let info = new GetBlockchainInfoResponse();
        info = props.info;
        return (
            <div className="header-card">
                <div className="center card-title">
                    Blockchain
                </div>
                <div className="center card-subtitle">
                    {info.getBitcoinNet() === 0 ? "Mainnet" : info.getBitcoinNet()}
                </div>
                <div className="center bold">
                    Best Block Height <CopyButton value={info.getBestHeight()} />
                </div>
                <div className="center pad-down">
                    <Link to={"/block/" + info.getBestHeight()}>{info.getBestHeight()}</Link>
                </div>
                <div className="center bold">
                    Best Block Hash <CopyButton value={reverseUint8arrIntoHex(info.getBestBlockHash_asU8())} />
                </div>
                <div className="center pad-down">
                    <Link to={"/block/" + reverseUint8arrIntoHex(info.getBestBlockHash_asU8())}>{reverseUint8arrIntoHex(info.getBestBlockHash_asU8())}</Link>
                </div>
                <div className="center bold">
                    Difficulty <CopyButton value={info.getDifficulty()} />
                </div>
                <div className="center pad-down">
                    {info.getDifficulty()}
                </div>
                <div className="center bold">
                    Median time (last 11 blocks) <CopyButton value={info.getMedianTime()} />
                </div>
                <div className="center pad-down">
                    {info.getMedianTime()}
                </div>
            </div>
        )
    }


    function MempoolInfoCard(props) {
        return (
            <div className="header-card">
                <div className="center card-title">
                    Mempool
                </div>
                <div className="center card-subtitle">
                    {"Live"}
                </div>
                <div className="center bold">
                    Transactions
                </div>
                <div className="center pad-down">
                    {props.size}
                </div>
                <div className="center bold">
                    Size (kB)
                </div>
                <div className="center pad-down">
                    {(props.bytes / 1024).toFixed(3)}
                </div>
            </div>
        )
    }


    function NodeInfoCard(props) {
        let info = props.info;
        return (
            <div className="header-card">
                <div className="center card-title">
                    Node
                </div>
                <div className="center card-subtitle">
                    BCHD
                </div>
                <div className="center bold">
                    Host
                </div>
                <div className="center pad-down">
                    {(props.client.serviceHost).split("//")[1]}
                </div>
                <div className="center bold">
                    Enabled Indexes
                </div>
                <div className="center">
                    {info.getTxIndex() ? "Transaction Index" : ""}
                </div>
                <div className="center">
                    {info.getAddrIndex() ? "Address Index" : ""}
                </div>
                <div className="center">
                    {info.getSlpIndex() ? "SLP Index" : ""}
                </div>
                <div className="center">
                    {info.getSlpGraphsearch() ? "SLP Graphsearch" : ""}
                </div>
            </div>
        )
    }

    function toggleFreeze(event) {
        event.preventDefault();
        setFreeze(!freeze);

    }

    function TransactionFeed(props) {
        return (
            <CardPanel className="header-card">
                <div className="right">
                    <Button tooltip="Freeze" onClick={toggleFreeze}><Icon>ac_unit</Icon></Button>
                </div>
                <div className="center absolute card-title">
                    Transaction Feed
                </div>

                {props.feed.length > 0 && [...props.feed].reverse().map(mempoolTx => {
                    return (
                        <TransactionCard key={mempoolTx.getTransaction().getHash_asB64()} mempoolTx={mempoolTx} />
                    )
                })
                }
            </CardPanel>
        )
    }

    function handleClick(link) {
        navigate(link);
    }

    function TransactionCard(props) {
        let mempoolTx = new MempoolTransaction();
        mempoolTx = props.mempoolTx;
        let tx = new Transaction();
        tx = mempoolTx.getTransaction();
        let hash = reverseUint8arrIntoHex(tx.getHash_asU8());
        let link = "/tx/" + hash;

        return (
            <CardPanel onClick={() => handleClick(link)} className="txfeed-card">
                <div className="center pad-down">
                    <Link to={link}>{hash}</Link>
                </div>
                <Row className="txfeed-row">
                    <Col s={4} className="left-align">
                        <div className="bold">Inputs</div>
                        <div className="">{tx.getInputsList().length}</div>
                    </Col>
                    <Col s={4} className="center">
                    </Col>
                    <Col s={4} className="right-align">
                        <div className="bold">Outupts</div>
                        <div className="">{tx.getOutputsList().length}</div>
                    </Col>
                </Row>
                <Row className="txfeed-row">
                    <Col s={4} className="left-align">
                        <div className="bold">Size</div>
                        <div className="">{tx.getSize()} Bytes</div>
                    </Col>
                    <Col s={4} className="center">
                        <div className="bold">{" "}</div>
                        <div className="">{" "}</div>
                        <div className="bold">Fee</div>
                        <div className="">{mempoolTx.getFee()} sats</div>
                    </Col>
                    <Col s={4} className="right-align">
                        <div className="bold">Value</div>
                        <div className="">{asBCH(calcTxValue(tx))} BCH</div>
                    </Col>
                </Row>
            </CardPanel>
        )
    }


    return (
        <Container>
            <CardPanel>
                <Row>
                    <Col s={12} m={6} l={4}>
                        <BlockchainInfoCard info={blockchainInfo} />
                    </Col>
                    <Col s={12} m={6} l={4}>
                        <MempoolInfoCard size={mempoolSize} bytes={mempoolBytes} />
                    </Col>
                    <Col s={12} m={6} l={4}>
                        <NodeInfoCard info={blockchainInfo} client={store.client} />
                    </Col>
                </Row>
            </CardPanel>
            {/* <Row>
                <Col s={12}> */}
            <TransactionFeed feed={txFeed} />
            {/* </Col>
            </Row> */}
        </Container>
    );
}

export default Home;