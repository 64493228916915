import React, { useEffect } from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from 'react-router-dom'

import './css/App.css';

import { useStore } from './store.js';

import Navigation from './components/Navigation'
import Footer from './components/Footer'
import Home from './Home';
import Transaction from './components/Transaction';
import Address from './components/Address';
import Block from './components/Block';

function App() {

    const { store } = useStore();

    useEffect(() => {
        store.darkMode ? document.querySelector('body').classList.add('dark') : document.querySelector('body').classList.remove('dark');
        console.log("apply dark mode:", store.darkMode);
    }, [store.darkMode]);

    return (
        <Router>
            <header>
                <Navigation />
            </header>
            <main>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="tx/:txid" element={<Transaction />} />
                    <Route path="addr/:address" element={<Address />} />
                    <Route path="block/:blockid" element={<Block />} />
                </Routes>
                <div className="center">gRCP server: {store.client.serviceHost.split("//")[1]}</div>
            </main>
            {/* <footer> */}
            <Footer />
            {/* </footer> */}
        </Router>
    );
}

export default App;
