import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import {
    Container,
    Row,
    Col,
    CardPanel,
    Button
} from 'react-materialize'
import {
    GetBlockInfoRequest,
    GetBlockInfoResponse,
    GetBlockRequest,
    GetBlockResponse,
    BlockInfo,
} from '../bchrpc/bchrpc_pb';
import {
    reverseHexIntoUint8arr,
    reverseUint8arrIntoHex,
} from '../utils'

import CopyButton from './CopyButton';
import ErrorModal from './ErrorModal';

import '../css/Transaction.css'

import { useStore } from '../store.js';


function Block() {
    const { store } = useStore();

    let params = useParams();

    const [error, setError] = useState({ state: false });

    function toggleErrorModal() {
        setError((error) => { return { state: !error.state } });
    }


    const [blockInfo, setBlockInfo] = useState(new GetBlockInfoResponse());
    const [block, setBlock] = useState(new GetBlockResponse());
    const [loadTransactions, setLoadTransactions] = useState(false);

    useEffect(() => {
        function getBlockInfo() {
            let blockInfoRequest = new GetBlockInfoRequest();

            if (Number(params.blockid)) {
                console.log("getBlockInfo params.blockid", Number(params.blockid));
                blockInfoRequest.setHeight(Number(params.blockid));
            } else {
                console.log("getBlockInfo params.blockid", params.blockid);
                blockInfoRequest.setHash(reverseHexIntoUint8arr(params.blockid));
            }

            const cancel = store.client.getBlockInfo(blockInfoRequest, store.headers, function (error, response) {
                if (error) {
                    console.log("error getBlockinfo:", error.code, error.message);
                    let errorMsg = {
                        state: true,
                        header: error.message,
                        content: error.code + " " + error.message + " (" + params.blockid + ")",
                    }
                    setError(errorMsg);

                } else {
                    setBlockInfo(response);
                }
            });

            return cancel;
        }

        setLoadTransactions(false);
        setBlockInfo(new GetBlockInfoResponse());

        const cancel = getBlockInfo();

        setBlock(new GetBlockResponse());

        return function cleanup() {
            console.log("cleanup getBlockInfo");
            cancel.cancel();
        }

    }, [params.blockid, store.client, store.headers]);


    useEffect(() => {
        function getBlock() {
            let blockRequest = new GetBlockRequest();
            if (Number(params.blockid)) {
                console.log(Number(params.blockid));
                blockRequest.setHeight(Number(params.blockid));
                blockRequest.setFullTransactions(false);
            } else {
                console.log(params.blockid);
                blockRequest.setHash(reverseHexIntoUint8arr(params.blockid));
                blockRequest.setFullTransactions(false);
            }
            const cancel = store.client.getBlock(blockRequest, store.headers, function (error, response) {
                if (error) {
                    console.log("error getBlockinfo:", error);
                } else {
                    // let message = new GetBlockResponse();
                    // message = response;
                    console.log("loadTransactions:", response.getBlock().getTransactionDataList().length);
                    setBlock(response);
                }
            });

            return cancel;
        }

        if (loadTransactions) {
            const cancel = getBlock();

            return function cleanup() {
                console.log("cleanup getBlock");
                cancel.cancel();
            }
        }

    }, [loadTransactions, params.blockid, store.client, store.headers]);


    function handleLoadTransactions() {
        setLoadTransactions(true);
    }


    function TransactionCard(props) {
        let hash = props.hash;
        let link = "/tx/" + hash;

        return (
            <CardPanel className="tx-card">
                <div className="center">
                    <Link to={link}>{hash}</Link>
                </div>
                {/* <div className=" right-align">
                    {txBalace > 0 ? "+" + txBalace : txBalace}
                </div> */}
            </CardPanel>
        )
    }


    function BlockHeader(props) {
        let blockInfo = new BlockInfo();
        blockInfo = props.blockInfo;

        return (
            <CardPanel className="header-card">
                <div className="center card-title">
                    Block {blockInfo.getHeight()} <CopyButton value={reverseUint8arrIntoHex(blockInfo.getHash_asU8())} />
                </div>
                <div className="center card-subtitle">
                    {reverseUint8arrIntoHex(blockInfo.getHash_asU8())}
                </div>
                {/* <Container> */}
                <Row>
                    <Col s={12} l={5} offset="">
                        <div className="bold">Size</div>
                        <div className="pad-down">{(blockInfo.getSize() / 1024).toFixed(4)} kB</div>
                        {blockInfo.get}
                        <div className="bold">Version</div>
                        <div className="pad-down">{blockInfo.getVersion()}</div>
                        <div className="bold">Nonce</div>
                        <div className="pad-down">{blockInfo.getNonce()}</div>
                        <div className="bold">Timestamp</div>
                        <div className="pad-down">{blockInfo.getTimestamp()}</div>

                    </Col>
                    <Col s={12} l={5} offset="l2" className="right-align-column">
                        <div className="bold">Confirmations</div>
                        <div className="pad-down">{blockInfo.getConfirmations()}</div>
                        <div className="bold">Difficulty Target</div>
                        <div className="pad-down">{blockInfo.getDifficulty()}</div>
                        <div className="bold"> Merkle Root</div>
                        <div className="pad-down">{reverseUint8arrIntoHex(blockInfo.getMerkleRoot_asU8())}</div>
                    </Col>
                </Row>
                <Row>
                    <Col s={12} l={5} offset="">
                        <div className="bold">Previous Block Hash</div>
                        <div className="pad-down">
                            <Link
                                to={"/block/" + reverseUint8arrIntoHex(blockInfo.getPreviousBlock_asU8())}
                            >
                                {reverseUint8arrIntoHex(blockInfo.getPreviousBlock_asU8())}
                            </Link>
                        </div>
                    </Col>
                    <Col s={12} l={5} offset="l2" className="right-align-column">
                        <div className="bold">Next Block Hash</div>
                        <div className="pad-down">
                            <Link
                                to={"/block/" + reverseUint8arrIntoHex(blockInfo.getNextBlockHash_asU8())}
                            >
                                {reverseUint8arrIntoHex(blockInfo.getNextBlockHash_asU8())}
                            </Link>
                        </div>
                    </Col>
                </Row>
                {/* </Container> */}
            </CardPanel>
        )
    }


    return (
        <React.Fragment>
            <Container>
                {!blockInfo.hasInfo() &&
                    <div className="center">
                        <h4>Loading...</h4>
                    </div>
                }
                {blockInfo.hasInfo() &&
                    <React.Fragment>
                        <BlockHeader blockInfo={blockInfo.getInfo()} />
                        {!loadTransactions &&
                            <div className="center">
                                <Button onClick={handleLoadTransactions}>Load Transactions...</Button>
                            </div>
                        }
                        {loadTransactions && !block.hasBlock() &&
                            <div className="center">
                                <h4>Loading...</h4>
                            </div>
                        }
                        {loadTransactions && block.hasBlock() &&
                            <Row>
                                <Col s={10} offset="s1">
                                    <div className="center column-title">Transactions ({block.getBlock().getTransactionDataList().length})</div>
                                    {block.getBlock().getTransactionDataList().map(tx => {
                                        return (<TransactionCard key={reverseUint8arrIntoHex(tx.getTransactionHash_asU8())} hash={reverseUint8arrIntoHex(tx.getTransactionHash_asU8())} />)
                                    })}
                                </Col>
                            </Row>
                        }
                    </React.Fragment>
                }

            </Container>
            <ErrorModal
                visible={error.state}
                header={error.header}
                content={error.content}
                toggleErrorModal={toggleErrorModal}
            />
        </React.Fragment>
    );
}

export default Block;