import React, {  } from 'react';
import M from "materialize-css";

function CopyButton(props) {

    function handleCopy(event) {
        event.preventDefault();
        navigator.clipboard.writeText(props.value);
        M.toast({ html: `Copied!<br />${props.value}` })
    }

    return (
        <a href="#!" onClick={handleCopy}><i className="material-icons tiny">content_copy</i></a>
    )
}

export default CopyButton;