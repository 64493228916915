import React, { useEffect, useState, useCallback } from 'react';
import {
    useParams,
    Link
} from 'react-router-dom';
import {
    Container,
    Row,
    Col,
    CardPanel,
    Collapsible,
    CollapsibleItem,
    Icon,
} from 'react-materialize'
import {
    GetAddressUnspentOutputsRequest,
    GetAddressUnspentOutputsResponse,
    GetAddressTransactionsRequest,
    GetAddressTransactionsResponse,
    SlpTokenMetadata,
    SlpTransactionInfo,
    UnspentOutput,
    Transaction as Tx,
} from '../bchrpc/bchrpc_pb';
import {
    reverseUint8arrIntoHex,
    validateAddress,
    slpToBchAddress,
    bchToSlpAddress,
} from '../utils'

import {
    asBCH,
    toDecimals 
} from '../bch_utils.ts';

import ErrorModal from './ErrorModal';

import '../css/Transaction.css'

import { useStore } from '../store.js';

// TODO
//  x group states to avoid unneeded rerender triggers
//  x useEffect cleanup() functions
//  x error modal
//  - split AddressHeader into AddressHeader and SimpleLedgerHeader
function Address() {
    const { store } = useStore();
    let params = useParams();

    const [error, setError] = useState({ state: false });
    function toggleErrorModal() {
        setError((error) => { return { state: !error.state } });
    }

    const [address, setAddress] = useState({})
    const [utxoList, setUtxoList] = useState(new GetAddressUnspentOutputsResponse());
    const [txList, setTxList] = useState(new GetAddressTransactionsResponse());


    const getAddressUnspentOutputs = useCallback(
        (address) => {
            let getAddressUnspentOutputsRequest = new GetAddressUnspentOutputsRequest();
            getAddressUnspentOutputsRequest.setAddress(address);
            getAddressUnspentOutputsRequest.setIncludeTokenMetadata(true);

            console.log("getAddressUnspentOutputs");

            const call = store.client.getAddressUnspentOutputs(getAddressUnspentOutputsRequest, store.headers, function (error, response) {
                if (error) {
                    console.log("error getAddressUnspentOutputs:", error.code, error.message);
                } else {
                    setUtxoList(response);
                }
            });

            return call;

        }, [store.client, store.headers]
    );


    const getAddressTransactions = useCallback(
        (address) => {
            let getAddressTransactionsRequest = new GetAddressTransactionsRequest();
            getAddressTransactionsRequest.setAddress(address);

            console.log("getAddressTransactions");

            const call = store.client.getAddressTransactions(getAddressTransactionsRequest, store.headers, function (error, response) {
                if (error) {
                    console.log("error getAddressTransactions:", error.code, error.message);
                    let errorMsg = {
                        state: true,
                        header: error.message,
                        content: error.code + " " + error.message + " (" + address + ")",
                    }
                    setError(errorMsg);
                } else {
                    console.log("getAddressTransactions");
                    setTxList(response);
                }
            });

            return call;

        }, [store.client, store.headers]
    );


    useEffect(() => {
        validateAddress(params.address, function (error, encodedCashAddress) {
            if (error) {
                // todo: show error modal
                console.log("error validateAddress:", error);
            } else {
                if (encodedCashAddress.split(":")[0] === "bitcoincash") {
                    setAddress({
                        bch: encodedCashAddress,
                        slp: bchToSlpAddress(encodedCashAddress),
                    });
                }
                if (encodedCashAddress.split(":")[0] === "simpleledger") {
                    setAddress({
                        bch: slpToBchAddress(encodedCashAddress),
                        slp: encodedCashAddress,
                    });
                }
            }
        });

        const utxoCall = getAddressUnspentOutputs(params.address);
        const txCall = getAddressTransactions(params.address);

        return function cleanup() {
            console.log("cleanup getAddressUnspentOutputs & getAddressTransactions");
            utxoCall.cancel();
            txCall.cancel();
        }

    }, [params.address, store.client, getAddressUnspentOutputs, getAddressTransactions]);


    function calcBalance(utxoList) {
        let balance = 0;
        for (let utxo of utxoList) {
            balance += utxo.getValue();
        }

        return balance;
    }


    function calcUnconfirmedBalance(address, mempoolTxList) {
        let balance = 0;
        for (let mempoolTx of mempoolTxList) {
            let tx = mempoolTx.getTransaction();

            for (let input of tx.getInputsList()) {
                if (input.getAddress() === address.split(":")[1]) {
                    balance -= input.getValue();
                }
            }
            for (let output of tx.getOutputsList()) {
                if (output.getAddress() === address.split(":")[1]) {
                    balance += output.getValue();
                }
            }
        }

        return balance;
    }


    function calcTokenBalance(tokenID, decimals, utxoList) {
        let balance = 0;

        for (let item of utxoList) {
            let utxo = new UnspentOutput();
            utxo = item;
            if (utxo.hasSlpToken()) {
                if (reverseUint8arrIntoHex(utxo.getSlpToken().getTokenId_asU8()) === tokenID) {
                    balance += Number(utxo.getSlpToken().getAmount());
                }
            }
        }

        return toDecimals(balance, decimals);
    }


    // todo: split into AddressHeader and SimpleLedgerHeader
    function AddressHeader(props) {
        return (
            <CardPanel className="header-card">
                <div className=" center">
                    <div className="card-title">Address</div>
                </div>
                <div className="center card-subtitle">{props.address.bch}</div>

                <div>
                    <div className="left" style={{ width: "50%" }}>
                        <div className="bold">Balance</div>
                        <div className="pad-down">{asBCH(calcBalance(props.utxoList))} BCH</div>
                    </div>
                    <div className="right right-align" style={{ width: "50%" }}>
                        <div className="bold">Unconfirmed Balance</div>
                        <div className="pad-down">{asBCH(calcUnconfirmedBalance(props.address.bch, props.mempoolTxList))} BCH</div>
                    </div>
                </div>

                <div className="bold">UTXO Count</div>
                <div className="pad-down">{props.utxoList.length}</div>
                {props.tokenMetaDataList.length > 0 &&
                    <div>
                        <div className="divider margin-float"></div>
                        <div className="card-title center">SLP Tokens</div>
                        <div className="center card-subtitle">{props.address.slp}</div>
                        <Collapsible accordion={false}>

                            {props.tokenMetaDataList.map(item => {
                                let tokenMetaData = new SlpTokenMetadata();
                                tokenMetaData = item;
                                // console.log(tokenMetaData.toObject())
                                if (tokenMetaData.hasV1Fungible()) {
                                    let tokenID = reverseUint8arrIntoHex(tokenMetaData.getTokenId_asU8());
                                    let tokenTicker = tokenMetaData.getV1Fungible().getTokenTicker();
                                    let tokenName = tokenMetaData.getV1Fungible().getTokenName();
                                    let decimals = tokenMetaData.getV1Fungible().getDecimals();
                                    return (
                                        <CollapsibleItem
                                            key={tokenMetaData.getTokenId_asB64()}
                                            expanded={false}
                                            header={calcTokenBalance(tokenID, decimals, props.utxoList) + " " + tokenTicker}
                                            icon={<Icon>arrow_drop_down</Icon>}
                                            node="div"
                                        >
                                            <div className="token-title bold pad-down">
                                                {tokenName} ({tokenTicker})
                                            </div>
                                            <Row>
                                                <Col s={12}>
                                                    <div className="bold">Token ID</div>
                                                    <div className="pad-down">{reverseUint8arrIntoHex(tokenMetaData.getTokenId_asU8())}</div>
                                                    <div className="bold">Mint Baton Hash</div>
                                                    <div className="pad-down">{reverseUint8arrIntoHex(tokenMetaData.getV1Fungible().getMintBatonHash_asU8())}</div>
                                                    <div className="bold">Token Document</div>
                                                    <div className="pad-down">{tokenMetaData.getV1Fungible().getTokenDocumentUrl()}</div>
                                                    <div className="bold">Token Document Hash</div>
                                                    <div className="pad-down">{reverseUint8arrIntoHex(tokenMetaData.getV1Fungible().getTokenDocumentHash_asU8())}</div>
                                                    <div className="bold">Decimals</div>
                                                    <div className="pad-down">{tokenMetaData.getV1Fungible().getDecimals()}</div>
                                                    <div className="bold">Balance</div>
                                                    <div className="pad-down">{calcTokenBalance(reverseUint8arrIntoHex(tokenMetaData.getTokenId_asU8()), tokenMetaData.getV1Fungible().getDecimals(), props.utxoList)} {tokenMetaData.getV1Fungible().getTokenTicker()}</div>
                                                </Col>
                                            </Row>
                                            {/* </div> */}
                                        </CollapsibleItem>
                                    )
                                } else {
                                    return (null)
                                }

                            })}

                        </Collapsible>
                    </div>
                }
            </CardPanel>
        )
    }


    function TransactionCard(props) {
        let tx = new Tx();
        tx = props.tx;
        let hash = reverseUint8arrIntoHex(tx.getHash_asU8());

        let tokenMetaDataList = props.tokenMetaDataList;
        let slpInfo = new SlpTransactionInfo();
        if (tx.hasSlpTransactionInfo()) {
            slpInfo = tx.getSlpTransactionInfo();
        }

        let link = "/tx/" + hash;

        let txBalace = 0;
        let sentTokens = {};
        let receivedTokens = {};

        tx.getInputsList().forEach(input => {
            if (input.getAddress() === address.bch.split(":")[1]) {
                txBalace -= input.getValue();
            }
            if (input.hasSlpToken() && input.getSlpToken().getAddress() === address.slp.split(":")[1]) {
                let tokenAmount = input.getSlpToken().getAmount();
                let token = reverseUint8arrIntoHex(input.getSlpToken().getTokenId_asU8());
                if (!sentTokens[token]) {
                    sentTokens[token] = { amount: Number(tokenAmount) };
                } else {
                    sentTokens[token].amount += Number(tokenAmount);
                }

            }
        });
        tx.getOutputsList().forEach(output => {
            if (output.getAddress() === address.bch.split(":")[1]) {
                txBalace += output.getValue();
            }

            if (output.hasSlpToken() && output.getSlpToken().getAddress() === address.slp.split(":")[1]) {
                let tokenAmount = output.getSlpToken().getAmount();
                let token = reverseUint8arrIntoHex(output.getSlpToken().getTokenId_asU8());
                if (!receivedTokens[token]) {
                    receivedTokens[token] = { amount: Number(tokenAmount) };
                } else {
                    receivedTokens[token].amount += Number(tokenAmount);
                }
            }
        });

        return (
            <CardPanel className="tx-card">
                <div className="">
                    <Link to={link}>{hash}</Link>
                </div>
                <div className="right-align">
                    {txBalace > 0 ? "+" + asBCH(txBalace) : "-" + asBCH(Math.abs(txBalace))} BCH
                </div>
                {/* <div className="right-align">
                    {txBalace > 0 ? "+" + txBalace : txBalace} sats
                </div> */}
                <div className="right-align">
                    {
                        slpInfo.hasV1Send() && tokenMetaDataList.map(tokenMetaData => {
                            let tokenID = reverseUint8arrIntoHex(tokenMetaData.getTokenId_asU8());
                            let decimals = tokenMetaData.getV1Fungible().getDecimals();
                            if (sentTokens[tokenID]) {
                                return (
                                    "-" + toDecimals(sentTokens[tokenID].amount, decimals) + " " + tokenMetaData.getV1Fungible().getTokenTicker()
                                );
                            } else {
                                return "";
                            }
                        })

                    }
                </div>
                <div className="right-align">
                    {
                        slpInfo.hasV1Send() && tokenMetaDataList.map(tokenMetaData => {
                            let tokenID = reverseUint8arrIntoHex(tokenMetaData.getTokenId_asU8());
                            let decimals = tokenMetaData.getV1Fungible().getDecimals();
                            if (receivedTokens[tokenID]) {
                                return (
                                    "+" + toDecimals(receivedTokens[tokenID].amount, decimals) + " " + tokenMetaData.getV1Fungible().getTokenTicker()
                                );
                            } else {
                                return "";
                            }
                        })

                    }
                </div>
            </CardPanel>
        )
    }


    return (
        <React.Fragment>
            <Container>
                {console.log("draw")}
                {!txList.getConfirmedTransactionsList().length > 0 && !txList.getUnconfirmedTransactionsList().length > 0 &&
                    <div className="center">
                        <h4>Loading...</h4>
                    </div>
                }
                {(txList.getConfirmedTransactionsList().length > 0 || txList.getUnconfirmedTransactionsList().length > 0) &&
                    <React.Fragment>

                        <AddressHeader
                            address={address}
                            tokenMetaDataList={utxoList.getTokenMetadataList()}
                            utxoList={utxoList.getOutputsList()}
                            mempoolTxList={txList.getUnconfirmedTransactionsList()}
                        />
                        {txList.getUnconfirmedTransactionsList().length > 0 &&
                            <Row>
                                <Col s={12}>
                                    <div className="center">Mempool Transactions ({txList.getUnconfirmedTransactionsList().length})</div>
                                    {txList.getUnconfirmedTransactionsList().map(tx => {
                                        return (
                                            <TransactionCard
                                                key={reverseUint8arrIntoHex(tx.getTransaction().getHash_asU8())}
                                                tx={tx.getTransaction()}
                                                tokenMetaDataList={utxoList.getTokenMetadataList()}
                                            />
                                        )
                                    })}
                                </Col>
                            </Row>
                        }
                        <Row>
                            <Col s={12}>
                                <div className="center">Transactions ({txList.getConfirmedTransactionsList().length})</div>
                                {txList.getConfirmedTransactionsList().map(tx => {
                                    return (
                                        <TransactionCard
                                            key={reverseUint8arrIntoHex(tx.getHash_asU8())}
                                            tx={tx}
                                            tokenMetaDataList={utxoList.getTokenMetadataList()}
                                        />
                                    )
                                })}
                            </Col>
                        </Row>
                    </React.Fragment>
                }

            </Container>
            <ErrorModal
                visible={error.state}
                header={error.header}
                content={error.content}
                toggleErrorModal={toggleErrorModal}
            />
        </React.Fragment>
    )
}

export default Address;
