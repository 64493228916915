import {
    // useEffect,
    useState
} from 'react';
import {
    Button,
    // Container,
    Modal, TextInput
} from 'react-materialize';
import {
    Link, useNavigate
} from 'react-router-dom';

import {
    isHex
} from '@bitauth/libauth';

import '../css/Navigation.css';

import SettingsModal from './SettingsModal';

import {
    validateAddress
} from '../utils';

import { useStore } from '../store.js';


function Navigation() {
    const { store, dispatch } = useStore();

    const navigate = useNavigate();

    const [query, setQuery] = useState("");
    const [searchError, setSearchError] = useState(false);

    function toggleDarkMode(event) {
        event.preventDefault();

        let action = {
            type: 'SET_DARKMODE',
            payload: !store.darkMode,
        }

        dispatch(action);
    }

    function handleQuery(event) {
        event.preventDefault();
        setQuery(event.target.value);
    }

    function handleSearch(event) {
        event.preventDefault();
        let q = query;
        console.log("search query:", q);
        setQuery(q);


        let link = "";
        if (Number(q) && Number(q) < 1000000) {
            console.log("query is number", Number(q));
            link = "/block/" + q;
        }

        if (link === "" && q.length === 64 && isHex(q)) {
            if (q.slice(0, 5) === "00000") {
                console.log("query is hex with leading zeroes, assuming block hash", q, q.length);
                link = "/block/" + q;
            } else {
                console.log("query is hex, assuming tx hash", q, q.length);
                link = "/tx/" + q;
            }
        }

        if (link === "") {
            validateAddress(q, (error, cashAddress) => {
                if (error) {
                    // do nothing
                    console.log("query is not an address", q, error);
                } else {
                    link = "/addr/" + cashAddress;
                }
            });
        }

        if (link !== "") {
            setQuery("");
            navigate(link);
        } else {
            setSearchError(true);
        }
    }

    function toggleSearchError() {
        setSearchError((searchError) => !searchError);
    }

    return (
        <div>
            <div className="navbar-fixed">
                <nav>
                    <div className="navbar">

                        <Link to="/" className="brand hide-on-med-and-down">A BCHD Block Explorer</Link>
                        <form className="search"  onSubmit={handleSearch}>
                            <TextInput
                                nolayout="true"
                                icon="search"
                                id="search"
                                placeholder="address || tx hash || block height || block hash"
                                value={query}
                                onChange={handleQuery}
                            />
                        </form>
                        <ul className="right ">
                            <li><a href="#!"><i onClick={toggleDarkMode} className="material-icons">{store.darkMode ? "brightness_high" : "brightness_4"}</i></a></li>
                            <li><a href="#modal1" className="modal-trigger"><i className="material-icons">settings</i></a></li>
                        </ul>
                    </div>
                </nav>
            </div>

            <SettingsModal />
            <Modal
                actions={[
                    <Button modal="close" node="button" onClick={toggleSearchError}>Close</Button>
                ]}
                bottomSheet={false}
                fixedFooter={false}
                header="Search Error"
                id="search-error-modal"
                open={searchError}
                options={{
                    dismissible: true,
                    endingTop: '10%',
                    inDuration: 250,
                    onCloseEnd: null,
                    onCloseStart: null,
                    onOpenEnd: null,
                    onOpenStart: null,
                    opacity: 0.5,
                    outDuration: 250,
                    preventScrolling: true,
                    startingTop: '4%'
                }}
                root={document.getElementById("body")}
            >
                Your search query ({query}) does not represent anything related to the Bitcoin Cash blockchain or network.
            </Modal>
        </div>
    );
}

export default Navigation;
