import React, {
    useEffect,
    useState,
} from 'react';
import { useParams, Link } from 'react-router-dom';
import {
    Container,
    Row,
    Col,
    CardPanel,
    Collapsible,
    CollapsibleItem,
    Icon
} from 'react-materialize'
import {
    GetTransactionRequest,
    GetTransactionResponse,
    SlpTokenMetadata,
    Transaction as Tx,
} from '../bchrpc/bchrpc_pb';
import {
    reverseHexIntoUint8arr,
    reverseUint8arrIntoHex,
    validateAddress,
    calcTxValue,
} from '../utils'

import CopyButton from './CopyButton';
import ErrorModal from './ErrorModal';

import '../css/Transaction.css'

import { useStore } from '../store.js';


function Transaction() {
    const { store } = useStore();

    const params = useParams();

    const [error, setError] = useState({ state: false });
    function toggleErrorModal() {
        setError((error) => { return { state: !error.state } });
    }

    const [txResponse, setTxResponse] = useState(new GetTransactionResponse());

    useEffect(() => {
        function getTransaction() {
            let getTransactionRequest = new GetTransactionRequest();
            let hash = reverseHexIntoUint8arr(params.txid);
            getTransactionRequest.setHash(hash);
            getTransactionRequest.setIncludeTokenMetadata(true);

            console.log("getTransaction:", params.txid);

            const call = store.client.getTransaction(getTransactionRequest, store.headers, function (error, response) {
                if (error) {
                    console.log("error getTransaction:", error);
                    let errorMsg = {
                        state: true,
                        header: error.message,
                        content: error.code + " " + error.message + " (" + params.txid + ")",
                    }
                    setError(errorMsg);
                } else {
                    setTxResponse(response);
                }
            });

            return call;
        }

        const call = getTransaction();

        return function cleanup() {
            console.log("cleaup getTransaction");
            call.cancel();
        }

    }, [params.txid, store.client, store.headers]);


    function isCoinbase(tx) {
        if (tx.getInputsList().length === 1 && tx.getInputsList()[0].getValue() === 0) {
            return true;
        } else {
            return false;
        }
    }


    function calcFee(tx) {
        let inputSum = 0;
        let outputSum = 0;

        if (isCoinbase(tx)) {
            return 0;
        } else {
            tx.getInputsList().forEach(input => {
                inputSum += input.getValue();
            });
            tx.getOutputsList().forEach(output => {
                outputSum += output.getValue();
            });

            return inputSum - outputSum;
        }
    }


    // function calcTxValue(tx) {
    //     let value = 0;
    //     tx.getOutputsList().forEach(output => {
    //         value += output.getValue();
    //     });

    //     return value;
    // }


    function TransactionHeader(props) {
        let tx = new Tx(); // enable code completion
        tx = props.tx;

        let tokenMetaData = new SlpTokenMetadata() // enable code completion
        tokenMetaData = props.tokenMetaData;

        let decimals = 0;
        let slpTransactedValue = 0;
        if (tokenMetaData && tx.hasSlpTransactionInfo() && tokenMetaData.hasV1Fungible()) {
            decimals = tokenMetaData.getV1Fungible().getDecimals();
            tx.getSlpTransactionInfo().getV1Send().getAmountsList().forEach((amount) => {
                slpTransactedValue += Number(amount);
            });
        }

        return (
            <CardPanel className="header-card">
                <div className="center card-title">
                    {isCoinbase(tx) ? "Coinbase Transaction" : "Transaction"} <CopyButton value={reverseUint8arrIntoHex(tx.getHash_asU8())} />
                </div>
                <div className="center card-subtitle">
                    {reverseUint8arrIntoHex(tx.getHash_asU8())}
                </div>
                {/* <Container> */}
                <Row>
                    <Col s={12} l={5} offset="">
                        <div className="bold">Value</div>
                        <div className="pad-down">{(calcTxValue(tx) / 100000000).toFixed(8)} BCH</div>
                        <div className="bold">Size</div>
                        <div className="pad-down">{tx.getSize()} Bytes</div>
                        <div className="bold">Fee</div>
                        <div className="pad-down">{calcFee(tx)} sats ({(calcFee(tx) / tx.getSize()).toFixed(3)} sats/B)</div>
                        <div className="bold">LockTime</div>
                        <div className="pad-down">{tx.getLockTime()}</div>
                    </Col>
                    <Col s={12} l={5} offset="l2" className="right-align-column">
                        <div className="bold">Confirmations</div>
                        <div className="pad-down">{tx.getConfirmations()}</div>
                        <div className="bold">Block Height</div>
                        <div className="pad-down"><Link to={"/block/" + tx.getBlockHeight()}>{tx.getBlockHeight()}</Link></div>
                        <div className="bold">Block Hash</div>
                        <div className="pad-down"><Link to={"/block/" + reverseUint8arrIntoHex(tx.getBlockHash_asU8())}>{reverseUint8arrIntoHex(tx.getBlockHash_asU8())}</Link></div>
                    </Col>
                </Row>
                {/* </Container> */}

                {tokenMetaData && tx.hasSlpTransactionInfo() && tokenMetaData.hasV1Fungible() &&
                    <Collapsible accordion={false}>
                        <CollapsibleItem
                            expanded={false}
                            header={"SLP Token Info (" + tokenMetaData.getV1Fungible().getTokenName() + ")"}
                            icon={<Icon>arrow_drop_down</Icon>}
                            node="div"
                        >
                            <div className="token-title pad-down">
                                {tokenMetaData.getV1Fungible().getTokenName()} ({tokenMetaData.getV1Fungible().getTokenTicker()})
                            </div>
                            <Row>
                                <Col s={12}>
                                    <div className="bold">Token ID</div>
                                    <div className="pad-down">{reverseUint8arrIntoHex(tx.getSlpTransactionInfo().getTokenId_asU8())}</div>
                                    <div className="bold">Mint Baton Hash</div>
                                    <div className="pad-down">{reverseUint8arrIntoHex(tokenMetaData.getV1Fungible().getMintBatonHash_asU8())}</div>
                                    <div className="bold">Token Document</div>
                                    <div className="pad-down">{tokenMetaData.getV1Fungible().getTokenDocumentUrl()}</div>
                                    <div className="bold">Token Document Hash</div>
                                    <div className="pad-down">{reverseUint8arrIntoHex(tokenMetaData.getV1Fungible().getTokenDocumentHash_asU8())}</div>
                                    <div className="bold">Decimals</div>
                                    <div className="pad-down">{tokenMetaData.getV1Fungible().getDecimals()}</div>
                                    <div className="bold">Transacted Value</div>
                                    <div className="">{(slpTransactedValue / (10 ** decimals)).toFixed(decimals)} {tokenMetaData.getV1Fungible().getTokenTicker()}</div>
                                </Col>
                            </Row>
                        </CollapsibleItem>
                    </Collapsible>
                }
            </CardPanel>
        )
    }


    function InputCard(props) {
        let input = new Tx.Input(); // enable code completion
        input = props.input;

        let tokenMetaData = new SlpTokenMetadata(); // enable code completion
        tokenMetaData = props.tokenMetaData;

        let address = input.getAddress();
        validateAddress(address, (error, cashAddress) => {
            if (error) {
                console.log("error validateAddress", error);
            } else {
                address = cashAddress;
            }
        });

        let bchAmount = input.getValue();
        let slpAddress;
        let slpAmount;
        let slpTicker;

        if (tokenMetaData && tokenMetaData.hasV1Fungible() && input.hasSlpToken()) {
            let decimals = tokenMetaData.getV1Fungible().getDecimals();
            slpAddress = "simpleledger:" + input.getSlpToken().getAddress();
            validateAddress(slpAddress, (error, cashAddress) => {
                if (error) {
                    console.log("error validateAddress", slpAddress, error);
                } else {
                    slpAddress = cashAddress;
                }
            });
            slpAmount = (input.getSlpToken().getAmount() / (10 ** decimals)).toFixed(decimals);
            slpTicker = tokenMetaData.getV1Fungible().getTokenTicker();
        }

        return (
            <CardPanel className="input-card">
                <div className="">
                    <Link to={"/addr/" + address}>{address}</Link>
                </div>
                <div className="right-align">{bchAmount} sats</div>
                {tokenMetaData && tokenMetaData.hasV1Fungible() && input.hasSlpToken() &&
                    <div>
                        <div className="divider margin-float"></div>
                        <div><Link to={"/addr/" + slpAddress}>{slpAddress}</Link> </div>
                        <div className="right-align">{slpAmount + " " + slpTicker} </div>
                    </div>
                }
            </CardPanel>
        )
    }


    function OutputCard(props) {
        let output = new Tx.Output(); // enable code completion
        output = props.output;

        let tokenMetaData = new SlpTokenMetadata(); // enable code completion
        tokenMetaData = props.tokenMetaData;

        let address = output.getAddress();
        validateAddress(address, (error, cashAddress) => {
            if (error) {
                console.log("error validateAddress", address, error);
            } else {
                address = cashAddress;
            }
        });

        let disassembledScript = output.getDisassembledScript();
        let bchAmount = output.getValue();
        let slpAddress;
        let slpAmount;
        let slpTicker;

        if (tokenMetaData && tokenMetaData.hasV1Fungible() && output.hasSlpToken()) {
            let decimals = tokenMetaData.getV1Fungible().getDecimals();
            slpAddress = output.getSlpToken().getAddress();
            validateAddress("simpleledger:" + slpAddress, (error, cashAddress) => {
                if (error) {
                    console.log("error validateAddress", slpAddress, error);
                } else {
                    slpAddress = cashAddress;
                }
            });
            slpAmount = (output.getSlpToken().getAmount() / (10 ** decimals)).toFixed(decimals);
            slpTicker = tokenMetaData.getV1Fungible().getTokenTicker();
        }

        return (
            <CardPanel className="output-card">
                <div className="">
                    {address ?
                        <Link to={"/addr/" + address}>{address}</Link>
                        :
                        disassembledScript
                    }
                </div>
                <div className="right-align">{bchAmount} sats</div>
                {tokenMetaData && tokenMetaData.hasV1Fungible() && output.hasSlpToken() &&
                    <div>
                        <div className="divider margin-float"></div>
                        <div><Link to={"/addr/" + slpAddress}>{slpAddress}</Link> </div>
                        <div className="right-align">{slpAmount + " " + slpTicker} </div>
                    </div>
                }

            </CardPanel>
        )
    }


    return (
        <React.Fragment>
            <Container>
                {!txResponse.hasTransaction() &&
                    <div className="center">
                        <h4>Loading...</h4>

                    </div>
                }
                {txResponse.hasTransaction() &&
                    <React.Fragment>
                        <TransactionHeader
                            tx={txResponse.getTransaction()}
                            tokenMetaData={txResponse.hasTokenMetadata() ? txResponse.getTokenMetadata() : null}
                        />
                        <Row>
                            <Col s={12} m={12} l={6}>
                                <div className="column-title center">
                                    Inputs ({txResponse.getTransaction().getInputsList().length})
                                </div>
                                {txResponse.getTransaction().getInputsList().map((input) => {
                                    return (
                                        <InputCard
                                            key={input.getIndex()}
                                            input={input}
                                            tokenMetaData={txResponse.hasTokenMetadata() ? txResponse.getTokenMetadata() : null}
                                        />
                                    )
                                })}
                            </Col>
                            <Col s={12} m={12} l={6}>
                                <div className="column-title center">
                                    Outputs ({txResponse.getTransaction().getOutputsList().length})
                                </div>
                                {txResponse.getTransaction().getOutputsList().map((output) => {
                                    return (
                                        <OutputCard
                                            key={output.getIndex()}
                                            output={output}
                                            tokenMetaData={txResponse.hasTokenMetadata() ? txResponse.getTokenMetadata() : null}
                                        />
                                    )
                                })}
                            </Col>
                        </Row>
                    </React.Fragment>
                }
            </Container>
            <ErrorModal
                visible={error.state}
                header={error.header}
                content={error.content}
                toggleErrorModal={toggleErrorModal}
            />
        </React.Fragment>
    );
}

export default Transaction;