import React, { createContext, useContext, useReducer } from 'react';

import * as pb from "./bchrpc/bchrpc_pb_service";
import { BrowserHeaders } from "browser-headers";

const headers = new BrowserHeaders();
headers.set("Access-Control-Allow-Origin", "*");
headers.set("Access-Control-Allow-Credentials", "true");
headers.set(
    "Access-Control-Allow-Headers",
    "Origin, Content-Type, Authorization, x-id, Content-Length, X-Requested-With, Date , Vary, Access-Control-Allow-Origin, Access-Control-Allow-Credentials"
);
headers.set("Access-Control-Allow-Methods", "GET, POST, PUT, DELETE, OPTIONS");

const mainnet = [
    "https://bchd.sploit.cash",
    "https://bchd.greyh.at:8335",
    "https://bchd.imaginary.cash:8335",
    "https://ryzen.electroncash.de:8335",
    "https://bchd.fountainhead.cash",
    "https://bchd2.fountainhead.cash",
    "https://bchd3.prompt.cash:8335",

];
// const testnet = [
//     "https://ryzen.electroncash.de:18335",
//     "https://bchd-testnet.electroncash.de:18335",
//     "https://bchd-testnet.greyh.at:18335",
// ];

let server;
if (localStorage.getItem("server")) {
    server = localStorage.getItem("server");
} else {
    server = mainnet[0];
}

let darkMode = false;
if (localStorage.getItem("darkmode")) {
    darkMode = JSON.parse(localStorage.getItem("darkmode"));
    console.log("saved dark mode:", darkMode);
}

const initialState = {
    servers: mainnet,
    // server: mainnet[0],
    client: new pb.bchrpcClient(server),
    headers: headers,
    darkMode: darkMode,
};

const StoreContext = createContext();

function storeReducer(store, action) {
    switch (action.type) {
        case 'SET_SERVER':
            localStorage.setItem("server", action.payload);
            return {
                ...store,
                client: new pb.bchrpcClient(action.payload),
            };
        case 'SET_DARKMODE':
            localStorage.setItem("darkmode", JSON.stringify(action.payload));
            return {
                ...store,
                darkMode: action.payload,
            };
        default:
            throw new Error("error StoreProvider: unknow action.type.");
    };
}

const StoreProvider = ({ children }) => {
    const [store, dispatch] = useReducer(storeReducer, initialState);

    // NOTE: you *might* need to memoize this value
    // Learn more in http://kcd.im/optimize-context
    const value = { store, dispatch };

    return <StoreContext.Provider value={value}>{children}</StoreContext.Provider>;
};

function useStore() {
    const context = useContext(StoreContext);
    if (context === undefined) {
        throw new Error('useStore must be used within a StoreProvider');
    }
    return context
}

export { StoreProvider, useStore }