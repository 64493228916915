import React, { } from 'react';
import {
    Modal,
    Button,
} from 'react-materialize'

function ErrorModal(props) {
    return (
        <Modal
            actions={[
                <Button flat modal="close" node="button" onClick={props.toggleErrorModal}>Close</Button>
            ]}
            bottomSheet={false}
            fixedFooter={false}
            header={props.header}
            id="error-modal"
            open={props.visible}
            options={{
                dismissible: true,
                endingTop: '10%',
                inDuration: 250,
                onCloseEnd: null,
                onCloseStart: null,
                onOpenEnd: null,
                onOpenStart: null,
                opacity: 0.5,
                outDuration: 250,
                preventScrolling: true,
                startingTop: '4%'
            }}
            root={document.getElementById("body")}
        >
            {props.content}
        </Modal>
    )
}

export default ErrorModal;
