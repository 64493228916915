const hasNonHexCharacter = /[^a-fA-F0-9]/u;
const isHexString = (maybeHexString) => typeof maybeHexString === 'string' &&
    !hasNonHexCharacter.test(maybeHexString);
const isInteger = (value) => typeof value === 'number' && Number.isInteger(value);
// const isPositiveInteger = (value) => isInteger(value) && value >= 0;
const isRangedInteger = (value, minimum, maximum) => isInteger(value) && value >= minimum && value <= maximum;


/**
 * Verify that a value is a valid `satoshi` value: either a number between `0`
 * and `Number.MAX_SAFE_INTEGER` or a 16-character, hexadecimal-encoded string.
 *
 * @param maybeSatoshis - the value to verify
 */
const isValidSatoshisValue = (maybeSatoshis) => {
    const uint64HexLength = 16;
    if (maybeSatoshis === undefined ||
        isRangedInteger(maybeSatoshis, 0, Number.MAX_SAFE_INTEGER) ||
        (isHexString(maybeSatoshis) && maybeSatoshis.length === uint64HexLength)) {
        return true;
    }
    return false;
};


/** Convert sats denomination to BCH
 * 
 * @param {*} sats value as number denominated in satoshis
 * @returns value as string denominated in BCH
 */
 export function asBCH(value: number | string): string {
    if (isValidSatoshisValue(value)) {
        let sats: number = Number(value);
        let bch: string = (sats / 100000000).toFixed(8);

        return bch;
    }

    return "error";
}


export function toDecimals(value: number | string, decimals: number): string {
    if (isValidSatoshisValue(value)) {
        let int: number = Number(value);
        let float: string = (int / 10 ** decimals).toFixed(decimals);

        return float;
    }

    return "error";
}