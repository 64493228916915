import React, {  } from 'react';
import {
    Button,
} from 'react-materialize'
import M from "materialize-css";


import '../css/Footer.css'

function Footer() {
    function wipeLocalStorage(event) {
        event.preventDefault();
        console.log("wipe local storage")
        localStorage.clear();

        M.toast({ html: 'local storage wiped!' })
    }

    return (
        <footer className="page-footer">
            <div className="container">
                <div className="row">
                    <div className="col l6 s12">
                        <h5 className="">A BCHD Bitcoin Cash Block Explorer dApp</h5>
                        <p className="">Powered by <a href="https://github.com/gcash/bchd/" target="_blank" rel="noreferrer">BCHD</a> gRPC API.</p>
                    </div>
                    <div className="col l4 offset-l2 s12">
                        <h5 className="">Links</h5>
                        <ul>
                            <li>
                                <a href="https://gitlab.com/acid.sploit/bchd-block-explorer" target="_blank" rel="noreferrer" title="GitLab Repository">
                                    <img src="/assets/img/gitlab.svg" alt="gitlab" />
                                </a>
                            </li>
                            <li>
                                <a href="https://api.cashaccount.info/display/sploit/100" target="_blank" rel="noreferrer" title="sploit#100 Bitcoin Cash CashAccount">
                                    <img src="/assets/img/cashaccount.svg" alt="cashaccount" />
                                </a>
                            </li>
                            <li>
                                <a href="https://t.me/bchchannel" target="_blank" rel="noreferrer" title="Bitcoin Cash Telegram">
                                    <img src="/assets/img/telegram.svg" alt="telegram" />
                                </a>
                            </li>
                            <li>
                                <a href="https://twitter.com/acidsploit" target="_blank" rel="noreferrer" title="@acidsploit Twitter">
                                    <img src="/assets/img/twitter.svg" alt="twitter" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="footer-copyright">
                <div className="container ">
                    © 2021 Copy whatever you want
                    <Button
                        className="right"
                        node="button"
                        flat
                        onClick={wipeLocalStorage}
                    >
                        Wipe local storage
                    </Button>
                </div>
            </div>
        </footer>
    );
}

export default Footer;