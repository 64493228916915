import React, { } from 'react';
import {
    Modal,
    Button,
} from 'react-materialize'

import CreatableSelect from 'react-select/creatable';

import { useStore } from '../store.js';

function SettingsModal() {
    const { store, dispatch } = useStore();

    function handleServer(new_value, meta) {
        let action = {
            type: 'SET_SERVER',
            payload: new_value.value,
        }
        dispatch(action);
    }

    return (
        <Modal
            actions={[
                <Button  modal="close" node="button">Close</Button>
            ]}
            bottomSheet={false}
            fixedFooter={false}
            header="Settings"
            id="modal1"
            open={false}
            options={{
                dismissible: true,
                endingTop: '10%',
                inDuration: 250,
                onCloseEnd: null,
                onCloseStart: null,
                onOpenEnd: null,
                onOpenStart: null,
                opacity: 0.5,
                outDuration: 250,
                preventScrolling: true,
                startingTop: '4%'
            }}
            root={document.getElementById("body")}
        >
            <CreatableSelect
                isClearable
                id="select-network"
                onChange={handleServer}
                options={store.servers.map(server => {
                    return {
                        label: "mainnet - " + server,
                        value: server,
                    }
                })}
            >
            </CreatableSelect>
        </Modal>
    )
}

export default SettingsModal;
